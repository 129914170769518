import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard2Service implements CanActivate {
  constructor( private api:ApiService,private router:Router
    
      ) {}

  canActivate(): boolean {
   
   if(this.api.getrole()=='shop')
  {      this.router.navigate(['boutique'])

    return true }
   else 
   {
     this.router.navigate(['notfound'])
   }
  }
}