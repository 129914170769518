import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) { }
  login(login,pass){
    return this.http.get("https://hamed.zenhosting.pro/login.php?login="+login+"&password="+pass)
  }
  addboutique(nom,adr){
    return this.http.get("https://hamed.zenhosting.pro/ajoutboutique.php?nom="+nom+"&adresse="+adr)

  }
  getboutique(){
    return this.http.get("https://hamed.zenhosting.pro/getboutique.php")
  }
  getproduit(){
    return this.http.get("https://hamed.zenhosting.pro/getproduit.php")
  }
  addproduit(idboutique,prix_vente,prix_achat,nom){
    return this.http.get("https://hamed.zenhosting.pro/ajoutproduit.php?nom="+nom+"&idboutique="+idboutique+"&prix_vente="+prix_vente+"&prix_achat="+prix_achat)

  }
  addagent(nom,prenom,numero,motpass,login,idboutique,role){
    return this.http.get("https://hamed.zenhosting.pro/ajoutuser.php?nom="+nom+"&idboutique="+idboutique+"&prenom="+prenom+"&numero="+numero+"&password="+motpass+"&login="+login+"&role="+role)

  }
  getagent(){
    return this.http.get("https://hamed.zenhosting.pro/getagent.php")
  }
  getprodbybout(id){
    return this.http.get("https://hamed.zenhosting.pro/getproduitbybout.php?id="+id)

  }
  adddepo(idp,idb,montant){
    return this.http.get("https://hamed.zenhosting.pro/ajoutfond.php?idproduit="+idp+"&idboutique="+idb+"&montant="+montant)

  }
  getdeposite(){
   return this.http.get("https://hamed.zenhosting.pro/getdepo.php")
  }
  getrole(){
    return (localStorage.getItem("roleeliisa"))
  }
  addclient(nom,id,tel){
    return this.http.get("https://hamed.zenhosting.pro/ajoutclient.php?fullname="+nom+"&idboutique="+id+"&numero="+tel)

  }
  getclient(id){
    return this.http.get("https://hamed.zenhosting.pro/getclient.php?id="+id)

  }
  adddepos(idproduit,idclient,montant,credit,idboutique){
    return this.http.get("https://hamed.zenhosting.pro/ajoutdepot.php?idproduit="+idproduit+"&idclient="+idclient+"&montant="+montant+"&credit="+credit+"&idboutique="+idboutique)

  }
  getclientdepo(id){
    return this.http.get("https://hamed.zenhosting.pro/getdepoclient.php?id="+id)
  }
  addretrait(idproduit,idclient,montant,idboutique){
    return this.http.get("https://hamed.zenhosting.pro/ajoutretrait.php?idproduit="+idproduit+"&idclient="+idclient+"&montant="+montant+"&idboutique="+idboutique)

  }
  getclientretrait(id){
    return this.http.get("https://hamed.zenhosting.pro/getclientretrait.php?id="+id)
  }
  getclientcredit(id){
    return this.http.get("https://hamed.zenhosting.pro/getclientcredit.php?id="+id)
  }
  getstat(id){
return this.http.get("https://hamed.zenhosting.pro/statagent.php?id="+id)
  }
  getstattab(id){
    return this.http.get("https://hamed.zenhosting.pro/statagenttab.php?id="+id)
  }
  payecredit(id,mon,tot){
    return this.http.get("https://hamed.zenhosting.pro/payecredit.php?id="+id+"&montant="+mon+"&tot="+tot)

  }
  boutiquelogin(id){
    return this.http.get("https://hamed.zenhosting.pro/boutiquelogin.php?id="+id)

  }
  getstatadmin(dd,df){
    return this.http.get("https://hamed.zenhosting.pro/adminapi/statagent.php?dd="+dd+"&df="+df)
  }
  gettabadmin(){
    return this.http.get("https://hamed.zenhosting.pro/adminapi/statagenttab.php")
  }
  getadmintabdate(dd,df){
    return this.http.get("https://hamed.zenhosting.pro/adminapi/stattabdate.php?dd="+dd+"&df="+df)

    //stattabdate
  }
}
//http://hamed.zenhosting.pro/login.php?login=ala&password=zen