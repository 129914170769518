import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {ApiService} from '../service/api.service'
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor( private api:ApiService,private router:Router
    
      ) {}

  canActivate(): boolean {
    if(this.api.getrole()=='admin')
   return true 
   else 
   if(this.api.getrole()=='shop')
   return true 
    else 
   {
     this.router.navigate(['notfound'])
     return false 
   }
  
  
  }
}