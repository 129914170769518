import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppapiService {

  constructor(private http:HttpClient) { }
  login(login,pass){
    let data=new FormData()
    data.append("login",login)
    data.append("password",pass)
    return this.http.post("https://hamed.zenhosting.pro/api/login.php",data)
  }
  adduser(nom,prenom,login,pass,adr,type,tel){
    let data=new FormData()
    data.append("nom",nom)
    data.append("adr",adr)
    data.append("prenom",prenom)
    data.append("numero",tel)
    data.append("pass",pass)
    data.append("type",type)
    data.append("login",login)
    return this.http.post("https://hamed.zenhosting.pro/api/ajoutboutique.php",data)

  }
  getboutique(){
    let data=new FormData()

    return this.http.post("https://hamed.zenhosting.pro/api/getboutique.php",data)
  }

  ajoutproduit(nom,pa,pv,dev,idb){
    let data=new FormData()
    data.append("nom",nom)
    data.append("achat",pa)
    data.append("vente",pv)
    data.append("idboutique",idb)
    data.append("devise",dev)

    return this.http.post("https://hamed.zenhosting.pro/api/ajoutproduit.php",data)

  }
  getproduitbybou(id){
    let data=new FormData()
    data.append("id",id)

    return this.http.post("https://hamed.zenhosting.pro/api/getproduitbybout.php",data)

  }
  adddepo(idp,idb,montant){
    let data=new FormData()
    data.append("idboutique",idb)
    data.append("idproduit",idp)
    data.append("montant",montant)

    return this.http.post("https://hamed.zenhosting.pro/api/ajoutfond.php",data)

  }
  getstatadmin(dd,df){
    let data=new FormData()

  }
  getproduit(){
    let data=new FormData()

    return this.http.post("https://hamed.zenhosting.pro/api/getproduit.php",data)

  }
  getdeposite(){
    let data=new FormData()

    return this.http.post("https://hamed.zenhosting.pro/api/getdeposite.php",data)

  }
  addaction(idb,idp,retrait,depot,da){
    let data=new FormData()
    data.append("idb",idb)
    data.append("idp",idp)
    data.append("retrait",retrait)
data.append('db',da)
    data.append("depot",depot)

    return this.http.post("https://hamed.zenhosting.pro/api/addaction.php",data)

  }
  getrapportagent(id){
    let data=new FormData()
    data.append("id",id)

return this.http.post("https://hamed.zenhosting.pro/api/rapportagent.php",data)
  }
  addcredit(idb,tel,nom,montant,op){
    let data=new FormData()
    data.append("id",idb)
    data.append("nom",nom)
    data.append("tel",tel)
    data.append("montant",montant)
    data.append("op",op)
    return this.http.post("https://hamed.zenhosting.pro/api/ajoutcredit.php",data)

  }
  getcreditbybout(id){
    let data=new FormData()
    data.append("id",id)

    return this.http.post("https://hamed.zenhosting.pro/api/getcreditbyboutique.php",data)

  }
  payercredit(id,montant,op,idb,mn){
    let data=new FormData()
    data.append("id",id)
    data.append("montant",montant)
    data.append("op",op)
    data.append("idb",idb)
    data.append("mn",mn)

    return this.http.post("https://hamed.zenhosting.pro/api/payecredit.php",data)

  }
  getrapportcreditagent(id){
    let data=new FormData()
    data.append("id",id)
    return this.http.post("https://hamed.zenhosting.pro/api/getrapportcreditagent.php",data)

  }
  augmentercredit(idb,f,montant,mn){
    let data=new FormData()
    data.append("id",f)
    data.append("montant",montant)
    data.append("mn",mn)
    data.append("idb",idb)
    return this.http.post("https://hamed.zenhosting.pro/api/augmentercredit.php",data)

  }
  getadminretrodepo(){
    let data=new FormData()

    return this.http.post("https://hamed.zenhosting.pro/api/adminstatdeporetrait.php",data)
  }
  boutiquelogin(id){
    let data=new FormData()
    data.append("id",id)
    return this.http.post("https://hamed.zenhosting.pro/api/boutiquelogin.php",data)

  }
  getadmin(){
    let data=new FormData()

    return this.http.post("https://hamed.zenhosting.pro/api/adminrap.php",data)
  }
  getadmin2(id){
    let data=new FormData()
    data.append("id",id)
    return this.http.post("https://hamed.zenhosting.pro/api/adminrap.php",data)
  }
  filtre(id,db,df){
    let data=new FormData()
    data.append("db",db)
    data.append("df",df)
    data.append("id",id)
    return this.http.post("https://hamed.zenhosting.pro/api/filtreagent.php",data)

  }
  filtrecredit(id,db,df){
    let data=new FormData()
    data.append("db",db)
    data.append("df",df)
    data.append("id",id)
    return this.http.post("https://hamed.zenhosting.pro/api/filtreagentcredit.php",data)

  }
  getadminretrodepo2(db,df){
    let data=new FormData()
data.append("db",db)
data.append("df",df)

    return this.http.post("https://hamed.zenhosting.pro/api/adminstatretrodepot2.php",data)
  }
  upadte(data){
    return this.http.post("https://hamed.zenhosting.pro/api/editmontant.php",data)

  }
  addcharge(data){
    return this.http.post("https://hamed.zenhosting.pro/api/addcharge.php",data)

  }
  getcharge(id)
  {
    return this.http.post("https://hamed.zenhosting.pro/api/getcharge.php",id)
  }
  deleteshop(id)
  {
    return this.http.post("https://hamed.zenhosting.pro/api/deleteshop.php",id)
  }
  deleteproduit(id){
    return this.http.post("https://hamed.zenhosting.pro/api/deleteproduit.php",id)

  }
  deletecharge(id){
    return this.http.post("https://hamed.zenhosting.pro/api/deletecharge.php",id)

  }
}
