import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public isMenuEnabled:boolean = true;
  public selectedIndex = 1;
  constructor(private router:Router) {}
  navigate(path, selectedId) {
    this.selectedIndex = selectedId;
    this.router.navigate([path]);
  }

  close() {
  //  menuController.toggle();
  }
  decon() {
    this.router.navigate(['./login']);
    this.close()
   // localStorage.clear()
  } 
}
