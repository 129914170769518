import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import {ApiService} from "./service/api.service"
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {AuthGuardService} from "./service/auth-guard.service"
import {AuthGuard2Service} from "./service/auth-guard2.service"

import {AppapiService} from "./service/appapi.service"

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,NgxDatatableModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },ApiService,AuthGuardService,AuthGuard2Service,AppapiService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
